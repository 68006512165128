import { default as AppManager } from './app-manager';
import { default as FragmentManager } from './fragment-manager';
import * as utils from './utils';
import { default as ConfigManager } from './config-manager';
import { default as AppearanceManager } from './appearance-manager';
import { default as UserPreferenceManager } from './preference-manager';
import eventBus from './event-bus';
import ProxyManager from './proxies';
import console from './log';
const eurekaMgrs = {
  ProxyManager,
  AppManager,
  FragmentManager,
  ConfigManager,
  AppearanceManager,
  UserPreferenceManager,
  utils,
  eventBus,
  console,
};
export default eurekaMgrs;
