let rawObjectDefineProperty = Object.defineProperty;

export function createProxyWindow(global) {
  // map always has the fastest performance in has check scenario
  // see https://jsperf.com/array-indexof-vs-set-has/23
  let propertiesWithGetter = new Map();
  propertiesWithGetter.set('event', true); //TODO workaround
  let proxyWindow = {};
  /*
   copy the non-configurable property of global to fakeWindow
   see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy/handler/getOwnPropertyDescriptor
   > A property cannot be reported as non-configurable, if it does not exists as an own property of the target object or 
     if it exists as a configurable own property of the target object.
   */

  Object.getOwnPropertyNames(global)
    .filter(function (p) {
      let descriptor = Object.getOwnPropertyDescriptor(global, p);
      return !(descriptor === null || descriptor === void 0 ? void 0 : descriptor.configurable);
    })
    .forEach(function (p) {
      let descriptor = Object.getOwnPropertyDescriptor(global, p);

      if (descriptor) {
        let hasGetter = Object.prototype.hasOwnProperty.call(descriptor, 'get');
        /*
       make top/self/window property configurable and writable, otherwise it will cause TypeError while get trap return.
       see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy/handler/get
       > The value reported for a property must be the same as the value of the corresponding target object 
         property if the target object property is a non-writable, non-configurable data property.
       */

        if (
          p === 'top' ||
          p === 'event' ||
          p === 'parent' ||
          p === 'self' ||
          p === 'window' ||
          (process.env.NODE_ENV === 'test' && (p === 'mockTop' || p === 'mockSafariTop'))
        ) {
          descriptor.configurable = true;
          /*
         The descriptor of window.window/window.top/window.self in Safari/FF are accessor descriptors, we need to avoid adding a data descriptor while it was
         Example:
          Safari/FF: Object.getOwnPropertyDescriptor(window, 'top') -> {get: function, set: undefined, enumerable: true, configurable: false}
          Chrome: Object.getOwnPropertyDescriptor(window, 'top') -> {value: Window, writable: false, enumerable: true, configurable: false}
         */

          if (!hasGetter) {
            descriptor.writable = true;
          }
        }

        if (hasGetter) {
          // freeze the descriptor to avoid being modified by zone.js
          propertiesWithGetter.set(p, true);
        }
        // see https://github.com/angular/zone.js/blob/a5fe09b0fac27ac5df1fa746042f96f05ccb6a00/lib/browser/define-property.ts#L71

        rawObjectDefineProperty(proxyWindow, p, Object.freeze(descriptor));
      }
    });
  return {
    proxyWindow,
    propertiesWithGetter,
  };
}
