const log = console.log;
const error = console.error;
const info = console.info;
const debug = console.debug;
const warn = console.warn;
const dir = console.dir;
const notProduction = process.env.NODE_ENV !== 'production';
const isLogDisabled = process.env.NODE_ENV === 'production' && location.search.indexOf('enableLog=true') < 0

console.log = notProduction
  ? log
  : (...args) => {
      if (isLogDisabled) {
        return null;
      }
      return log.apply(console, args);
    };

console.debug = notProduction
  ? debug
  : (...args) => {
      if (isLogDisabled) {
        return null;
      }
      return debug.apply(console, args);
    };

console.error = notProduction
  ? error
  : (...args) => {
      if (isLogDisabled) {
        return null;
      }
      return error.apply(console, args);
    };

console.info = notProduction
  ? info
  : (...args) => {
      if (isLogDisabled) {
        return null;
      }
      return info.apply(console, args);
    };

console.warn = notProduction
  ? warn
  : (...args) => {
      if (isLogDisabled) {
        return null;
      }
      return warn.apply(console, args);
    };
console.dir = notProduction
  ? dir
  : (...args) => {
      if (isLogDisabled) {
        return null;
      }
      return dir.apply(console, args);
    };
window.console = console;
export default console;
