import { isBoundedFunction, isCallable, isConstructable } from './utils';
let currentRunningSandboxProxy;
export function getCurrentRunningSandboxProxy() {
  return currentRunningSandboxProxy;
}
export function setCurrentRunningSandboxProxy(proxy) {
  currentRunningSandboxProxy = proxy;
}
let functionBoundedValueMap = new WeakMap();
export function getTargetValue(target, value) {
  let cachedBoundFunction = functionBoundedValueMap.get(value);

  if (cachedBoundFunction) {
    return cachedBoundFunction;
  }

  if (isCallable(value) && !isBoundedFunction(value) && !isConstructable(value)) {
    // some callable function has custom fields, 
    // we need to copy the enumerable props to boundValue. such as moment function.
    let boundValue = Function.prototype.bind.call(value, target);
    // use for..in rather than Object.keys.forEach for performance reason
    // eslint-disable-next-line guard-for-in,no-restricted-syntax

    for (let key in value) {
      boundValue[key] = value[key];
    } // copy prototype if bound function not have

    if (value.hasOwnProperty('prototype') && !boundValue.hasOwnProperty('prototype')) {
      boundValue.prototype = value.prototype;
    }
    functionBoundedValueMap.set(value, boundValue);
    return boundValue;
  }

  return value;
}
